import React, { useEffect, useMemo } from "react";
import { Redirect, Switch, useHistory, useLocation } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { cancelAxios } from "../helpers/axios";
// Private Pages
import Company from "../components/company";
// import Watchlist from "../components/watchlist";
import SignOut from "../components/auth/SignOut";
// Protected pages
import SignIn from "../components/auth/SignIn";
// Public pages
import HomePage from "../components/homePage";
import HomepageDX from "../components/homePage/indexDX";
import HomepageDXOld from "../components/homePage/indexDX-old";

// Semi-public pages
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import FavoritesContainer from "../components/TradingView/ChartGrid/FavoritesContainer";
import MovementContainer from "../components/TradingView/MovementContainer";
import NotificationsTable from "../components/common/Tables/NotificationsContainer";
import Tutorials from "../components/Tutorials/TutorialsContainer";
import BasicsTraining from "../components/BasicsTraining/BasicsTrainingContainer";
import OptionScanner from "../components/OptionScanner/OptionScannerContainer";
import RadarScannerContainer from "../components/TradingView/RadarScannerContainer";
import ResetPassword from "../components/auth/ResetPassword";
import FallbackError from "../components/common/FallbackError";
import LiveOptionsContainer from "../components/LiveOptions/LiveOptionsContainer";
import { liveOptionsLoad } from "../appRedux/ducks/newLiveOptions";
import { useMedia } from "react-media";
import { useAuth } from "../contexts/Auth";
import SwingTradesContainer from "../components/SwingTrades/SwingTradesContainer";
import WarningSignalsContainer from "../components/WarningSignals/WarningSignalsContainer";
import SwingTradesOptionsContainer from "../components/SwingTrades/SwingTradesOptionsContainer";
import { cleanMessageQueue } from "../appRedux/ducks/socket";
import useSound from "use-sound";
import chaching from "../assets/sounds/chaching.mp3";
import alarm from "../assets/sounds/alarm.mp3";
import everyone from "../assets/sounds/everyone.mp3";
import casinoMoney from "../assets/sounds/casinoMoney.mp3";
import DarkFlowHistory from "../components/common/Tables/DarkflowHistory";
import { isInternalWS } from "../appRedux/actions/helpers";
import LiveWalkThrough from "../components/LiveWalkThrough/LiveWalkThroughContainer";
import MarketAnalysis from "../components/MarketAnalysis/MarketAnalysisContainer";
// import moment from "moment";
import PlatinumProgram from "../components/PlatinumProgram";
import PlatinumProgramSignUp from "../components/PlatinumProgram/SignUp/PlatinumProgramSignUp";
import MentorshipPaywellConfirm from "../components/MentorshipPaywellConfirm";
import StarterThankYou from "../components/OptionsMentorship/StarterThankYou";
import IntermediateThankYou from "../components/OptionsMentorship/IntermediateThankYou";
import AdvancedThankYou from "../components/OptionsMentorship/AdvancedThankYou";
import PlatinumThankYou from "../components/OptionsMentorship/PlatinumThankYou";
import SwingSuggestions from "../components/SwingSuggestions";
import DayTradeWithBrian from "../components/DaytradeWithBrian";
import SignUp from "../components/auth/SignUp";
import SignUpThankYou from "../components/auth/SignUpThankYou";
import OptionsAcademySignup from "../components/OptionsAcademy/OptionsAcademySignup";
import OptionsAcademyThankYou from "../components/OptionsAcademy/OptionsAcademyThankYou";
import OptionsAcademy from "../components/OptionsAcademy/OptionsAcademy";
import FreeTier from "../components/FreeTier";
// import UserProfile from "../components/UserProfile";
import TradeGPT from "../components/TradeGPT";
import EmeraldSignup from "../components/EmeraldSignup";
import Emerald from "../components/Emerald";
import SettingsPage from "../components/Settings/SettingsPage";
import NewOptionsMentorship from "../components/NewOptionsMentorship/NewOptionsMentorship";
import TradealgoProducts from "../components/TradealgoProducts/TradealgoProducts";
import { USER_SETTINGS_RESET } from "../appRedux/ducks/userSettings";
import WealthSeriesSignUp from "../components/WealthSeriesSignUp/WealthSeriesSignUp";
import WealthSeriesOverview from "../components/WealthSeriesOverview/WealthSeriesOverview";
import WealthSeriesPortfolioPage from "../components/WealthSeriesPortfolioPage/WealthSeriesPortfolioPage";
import WealthSeriesLiteSignUp from "../components/WealthSeriesLiteSignUp/LiteSignUp";
import WealthSeriesLiteOnboarding from "../components/WealthSeriesLiteOnboarding/LiteOnboarding";
import LiteContainer from "../components/WealthSeriesLite/LiteContainer";
import DeletedAccountPage from "../components/UserProfile/DeletedAccount";
import MobileStockContainer from "../components/TradingView/MobileStockContainer";
import MobileVimeoPlayer from "../components/Mobile/MobileVimeoPlayer";

const mediaQuery = {
  isMobile: "screen and (max-width: 991px)",
};

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: theme.palette.primary.background + " !important",
  },
  text: {
    color: "white",
  },
}));

const Routes = ({
  themeMode,
  layoutMode,
  messages,
  cleanQueue,
  userSettings,
  alertMessages,
}) => {
  const [playCashOG] = useSound(chaching);
  const [playAlarm] = useSound(alarm);
  const [playEveryone] = useSound(everyone);
  const [palyCasinoMoney] = useSound(casinoMoney);
  const { fetchUser } = useAuth();
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { notifications } = userSettings;
  const triggerFetchUser = useSelector(
    (state) => state.userSettings.triggerFetchUser,
  );
  useEffect(() => {
    if (triggerFetchUser) {
      fetchUser();
      dispatch({ type: USER_SETTINGS_RESET });
    }
  }, [triggerFetchUser, dispatch]);
  useEffect(() => {
    const notiMode = notifications?.mode || 0;
    if (Number(notiMode) !== 0 && notifications?.sound) {
      let soundPlayed = false;

      messages.forEach((message) => {
        if (!soundPlayed) {
          const isOfficial =
            message?.analyst_id === "Py7jBgYDxVTgiP6jVOHb38aDL4F3" &&
            message?.message?.includes("@official");
          const isEveryoneOrFam =
            message?.analyst_id === "Py7jBgYDxVTgiP6jVOHb38aDL4F3" &&
            (message?.message?.includes("@everyone") ||
              message?.message?.includes("@fam"));
          const isHighRiskTrade =
            message?.analyst_id === "Py7jBgYDxVTgiP6jVOHb38aDL4F3" &&
            message?.message?.includes("@highrisktrade");

          if (Number(notiMode) === 2 || Number(notiMode) === 1) {
            if (isOfficial) {
              playCashOG();
              soundPlayed = true;
            } else if (isEveryoneOrFam) {
              playEveryone();
              soundPlayed = true;
            } else if (isHighRiskTrade) {
              playAlarm();
              soundPlayed = true;
            } else if (Number(notiMode) === 1) {
              playCashOG();
              soundPlayed = true;
            }
          }
        }
      });
    }

    if (messages.length) {
      cleanQueue();
    }
  }, [JSON.stringify(messages), pathname, notifications]);

  useEffect(() => {
    const notiMode = notifications?.mode || 0;
    if (Number(notiMode) === 2) {
      alertMessages.forEach(() => {
        // ALERT BOARD PLAY CASINO MONEY
        if (notifications?.sound || false) palyCasinoMoney();
      });
    }
  }, [JSON.stringify(alertMessages), JSON.stringify(notifications)]);

  const { isMobile } = useMedia({
    queries: mediaQuery,
  });
  const { tierLevel } = useAuth();
  const location = useLocation();
  const params = history?.location?.pathname?.split("/");
  const classes = useStyles();
  let param = params[1];
  let param2 = params[2] ?? "";

  useEffect(() => {
    document.querySelector("body")?.classList?.remove("dark");
    document.querySelector("body")?.classList?.remove("light");
    document.body.className = "";

    // Set body class to our current page home-page is the initial launchscreen/login page
    if (param === "wealth-series") {
      document.querySelector("body")?.classList?.add(param + "-" + param2);
    } else {
      document
        .querySelector("body")
        ?.classList?.add(param === "" ? "home-page" : param);
    }

    document.querySelector("body")?.classList?.add(themeMode);
    const light = themeMode === "light";
    const root = document.documentElement;
    root.style.setProperty(
      "--background-color-scroll",
      light ? "white" : "#000000d6",
    );
  }, [themeMode, location]);

  useEffect(() => {
    cancelAxios("Navigated away"); // Stop all api requests during url change
  }, [location?.pathname]);

  return useMemo(() => {
    return (
      <div className={clsx(classes.background, "appWrapper")}>
        <Switch>
          <PublicRoute
            exact
            path="/signin"
            component={SignIn}
            type="protected"
            fixedPage
            pageCenter
          />
          <PublicRoute
            exact
            path="/mobile-chart/:symbol"
            component={MobileStockContainer}
            fixedPage
            pageCenter
            type="mobile"
          />
          {/* <PublicRoute
            exact
            path="/mobile/scanner"
            component={RadarScannerContainer}
            fixedPage
            pageCenter
            type="mobile"
          />
          <PublicRoute
            exact
            path="/mobile/vimeo"
            component={MobileVimeoPlayer}
            fixedPage
            pageCenter
            type="mobile"
          /> */}
          <PublicRoute
            exact
            path="/mobile/scanner"
            component={RadarScannerContainer}
            fixedPage
            pageCenter
            type="mobile"
          />
          <PublicRoute
            exact
            path="/mobile/vimeo"
            component={MobileVimeoPlayer}
            fixedPage
            pageCenter
            type="mobile"
          />
          <PublicRoute
            exact
            path="/signup"
            component={SignUp}
            type="protected"
            fixedPage
            pageCenter
          />
          <PublicRoute
            exact
            path="/signup-thank-you"
            component={SignUpThankYou}
            type="protected"
            fixedPage
            pageCenter
          />
          <Redirect exact={true} from="/home" to="/home/Intraday/Auto/Up" />
          <Redirect exact={true} from="/historical" to="/historical/Auto/Up" />
          <Redirect exact={true} from="/swing-trades" to="/swing-trades/0" />
          <Redirect
            exact={true}
            from="/live-options"
            to="/live-options/35000/730"
          />
          <Redirect exact={true} from="/alerts" to="/alerts/Auto/Up" />
          <PrivateRoute
            internal
            exact
            path="/home/*"
            component={
              isInternalWS
                ? HomePage
                : layoutMode === "new"
                  ? HomepageDX
                  : HomepageDXOld
            }
            name="Main Dashboard"
          />

          <PrivateRoute
            internal
            exact
            path="/option-chain/:sym"
            component={OptionScanner}
            name="Option Chain"
          />
          <PublicRoute
            exact
            path="/reset-password"
            component={ResetPassword}
            type="protected"
            fixedPage
            pageCenter
          />
          <PrivateRoute
            exact
            name="signOut"
            path="/signout"
            type="protected"
            component={SignOut}
          />
          <PublicRoute
            exact
            path="/"
            component={SignIn}
            type="protected"
            fixedPage
            pageCenter
          />
          <PrivateRoute
            internal
            exact
            path="/historical/*"
            name={isMobile ? "Historical ATS" : "Historical DarkFlow"}
            component={DarkFlowHistory}
          />
          <PublicRoute
            internal
            exact
            path="/deleted-account"
            name={`Deleted Account`}
            component={DeletedAccountPage}
          />
          <PrivateRoute
            internal
            exact
            path="/market-analysis"
            name={`Live Trading Room`}
            component={MarketAnalysis}
            liveStream={true}
          />
          <PrivateRoute
            tier={1}
            exact
            oaRoute={true}
            path="/options-academy/:main/:sub?"
            name={`Options Academy`}
            component={OptionsAcademy}
          />
          <PrivateRoute
            exact
            path="/options-academy-signup"
            name={`TradeAlgo Options Academy`}
            component={OptionsAcademySignup}
          />
          <PrivateRoute
            exact
            path="/options-academy-signup"
            name={`TradeAlgo Options Academy`}
            component={OptionsAcademySignup}
          />
          <PrivateRoute
            exact
            path="/options-academy-thank-you"
            name={`TradeAlgo Options Academy`}
            component={OptionsAcademyThankYou}
          />
          <PrivateRoute
            exact
            path="/options-mentorship-signup"
            name={`TradeAlgo Options Mentorship`}
            component={MentorshipPaywellConfirm}
          />
          <PrivateRoute
            exact
            path="/free-tier"
            name={`Free Account`}
            component={FreeTier}
          />
          <PrivateRoute
            exact
            path="/options-mentorship-starter-thank-you"
            name={`TradeAlgo Options Mentorship`}
            component={StarterThankYou}
          />
          <PrivateRoute
            exact
            path="/options-mentorship-intermediate-thank-you"
            name={`TradeAlgo Options Mentorship`}
            component={IntermediateThankYou}
          />
          <PrivateRoute
            exact
            path="/options-mentorship-advanced-thank-you"
            name={`TradeAlgo Options Mentorship`}
            component={AdvancedThankYou}
          />
          <PrivateRoute
            exact
            path="/options-mentorship-platinum-thank-you"
            name={`TradeAlgo Options Mentorship`}
            component={PlatinumThankYou}
          />
          <PrivateRoute
            exact
            path="/platinum-program/sign-up"
            name={`Platinum Program 💎`}
            component={PlatinumProgramSignUp}
          />
          <PrivateRoute
            tier={1}
            exact
            platRoute={true}
            path="/platinum-program/:main?/:sub"
            name={`Platinum Program 💎`}
            component={PlatinumProgram}
          />
          <PrivateRoute
            internal
            exact
            path="/tutorials"
            name={`Tutorials`}
            component={Tutorials}
            liveStream={true}
          />
          <PrivateRoute
            internal
            exact
            path="/movement"
            name="Movement"
            component={MovementContainer}
          />
          <PrivateRoute
            exact
            path="/wealth-series-lite-signup"
            name="Wealth Series Lite"
            component={WealthSeriesLiteSignUp}
          />
          <PrivateRoute
            wealthRoute
            exact
            path="/wealth-series-lite-onboarding"
            name="Wealth Series Lite"
            component={WealthSeriesLiteOnboarding}
          />
          <PrivateRoute
            wealthRoute
            exact
            path="/wealth-series-lite/:tab"
            name="Wealth Series"
            component={LiteContainer}
          />
          <PrivateRoute
            exact
            path="/wealth-series-signup"
            name="Wealth Series"
            component={WealthSeriesSignUp}
          />
          <PrivateRoute
            wealthRoute={true}
            exact
            path="/wealth-series/:series"
            name="Wealth Series"
            component={WealthSeriesOverview}
          />

          <PrivateRoute
            wealthRoute={true}
            exact
            path="/wealth-series/:series/:portfolio"
            name="Wealth Series"
            component={WealthSeriesPortfolioPage}
          />
          <PrivateRoute
            exact
            path="/emerald-signup"
            name="Emerald Inc"
            component={EmeraldSignup}
          />
          <PrivateRoute
            internal
            exact
            emeraldRoute={true}
            path="/emerald/:tab/:videoID?"
            name="Emerald Inc"
            component={Emerald}
          />
          <PrivateRoute
            exact
            path="/wealth-series-signup"
            name="Wealth Series"
            component={WealthSeriesSignUp}
          />
          <PrivateRoute
            exact
            path="/tradegpt"
            name="TradeGPT"
            component={TradeGPT}
          />
          <PrivateRoute
            internal
            exact
            path="/chart-grid"
            name={`Chart Grid`}
            component={FavoritesContainer}
            liveStream={true}
          />
          <PrivateRoute
            internal
            exact
            path="/live-training"
            name={`Live Training`}
            component={BasicsTraining}
            liveStream={true}
          />
          {/* <PrivateRoute
            internal
            exact
            path="/advanced-training"
            name={`Advanced Training`}
            component={AdvancedTraining}
            liveStream={true}
          /> */}
          <PrivateRoute
            internal
            exact
            path="/live-walkthrough"
            name={`Live Walkthrough`}
            component={LiveWalkThrough}
            liveStream={true}
          />
          <PrivateRoute
            internal={false}
            exact
            path="/company/:symbol"
            name={`Company`}
            component={Company}
          />
          {/* <PrivateRoute
            internal={false}
            exact
            path="/user-profile"
            name={`User Profile`}
            component={UserProfile}
          /> */}
          <PrivateRoute
            internal
            exact
            path="/mutual-fund/:symbol"
            name={`Fund`}
            component={Company}
          />
          <PrivateRoute
            internal
            exact
            path="/etf/:symbol"
            name={`ETF`}
            component={Company}
          />
          <PrivateRoute
            internal
            exact
            path="/live-options/*"
            name={`Live Options`}
            component={LiveOptionsContainer}
          />
          <PrivateRoute
            internal
            exact
            path="/scanner"
            component={RadarScannerContainer}
            name="Scanner"
          />
          <PrivateRoute
            internal
            exact
            name="Daytrade With Brian"
            path="/live-chat/:tab"
            component={DayTradeWithBrian}
          />
          <PrivateRoute
            internal
            exact
            path="/alerts/*"
            name="Alerts"
            component={NotificationsTable}
          />
          <PrivateRoute
            internal
            exact
            path="/swing-trades/*"
            name={`SwingTrade AI`}
            component={SwingTradesContainer}
          />
          <PrivateRoute
            internal
            exact
            path="/options-ai"
            name={`Options AI`}
            component={SwingTradesOptionsContainer}
          />
          <PrivateRoute
            // internal
            exact
            path={`/settings/:tab`}
            name={`Settings`}
            component={SettingsPage}
          />
          <PrivateRoute
            internal={true}
            exact
            path="/warning-signals"
            name={`Early Warning Signals`}
            component={WarningSignalsContainer}
          />

          <PrivateRoute
            internal
            exact
            path="/swing-suggestions-secret"
            name={`Swing Suggestions`}
            component={SwingSuggestions}
          />
          {/* <PrivateRoute
            exact
            //private options mentorship route
            path="/options-mentorship/:level"
            name={`Options Mentorship`}
            component={OptionsMentorship}
          /> */}
          <PrivateRoute
            exact
            path="/options-mentorship/:tab?"
            name={`New Options Mentorship`}
            component={NewOptionsMentorship}
          />
          <PrivateRoute
            exact
            path="/products"
            name={`Tradealgo Products`}
            component={TradealgoProducts}
          />
          <PrivateRoute notFound={true} component={FallbackError} />
        </Switch>
      </div>
    );
  }, [history, location.pathname, tierLevel, layoutMode]);
};

const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode,
  layoutMode: state.layoutMode.layoutMode,
  messages: state.Socket.snackbarMessages,
  userSettings: state.userSettings.userSettings,
  alertMessages: state.Socket.alertMessages,
});
const dispatchToProps = (dispatch) => ({
  liveFetchOptions: (tickers, prem_min, exp_within, last_time, date) =>
    dispatch(liveOptionsLoad(tickers, prem_min, exp_within, last_time, date)),
  cleanQueue: () => dispatch(cleanMessageQueue()),
});

export default connect(stateToProps, dispatchToProps)(Routes);
